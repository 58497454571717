@charset "UTF-8";
/*------------------------------------*    STYLE.CSS
\*------------------------------------*/
/**
 *
 * sass --watch style.scss:style.min.css --style compressed
 *
 * Here we pull in some variables, include the inuit.css framework, then add our
 * project-specific components afterwards.
 */
/**
 * Setup
 */
/* ------------------------------------------------------------ *	Variables
\* ------------------------------------------------------------ */
/*  Breakpoints  */
/*  Fonts  */
/*  Bootstrap  */
/*  Colors  */
/*  Misc  */
/**
 * She’s all yours, cap’n... Begin importing your stuff here.
 */
html, body {
  background: #282828; }

body.login {
  font-family: "Lato", Helvetica, Arial, sans-serif; }

body.login form#loginform,
body.login form#lostpasswordform,
body.login form,
body.login #wp-submit,
body.login .message {
  box-shadow: none; }

body.login #login {
  margin-bottom: 50px; }

body.login #login h1 a {
  background: transparent url(../images/bpdwhite.png) no-repeat;
  width: 160px;
  height: 106px;
  background-size: contain;
  pointer-events: none;
  margin-bottom: 40px; }

body.login #login form p.forgetmenot {
  display: block;
  float: none;
  width: 100%;
  margin-bottom: 10px; }

body.login #login #backtoblog,
body.login #login #nav {
  text-align: center; }

body.login #login #backtoblog a,
body.login #login #nav a {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.6em;
  color: #c9cdd2; }

body.login #login #backtoblog {
  display: none; }

body.login form#lostpasswordform,
body.login form#loginform,
body.login form {
  background-color: white;
  color: #323443;
  padding-bottom: 25px; }

body.login form#lostpasswordform label,
body.login form#loginform label,
body.login form label {
  font-size: 14px;
  color: #323443;
  width: 100%;
  margin-bottom: 4px;
  font-weight: 600; }

body.login input[type="text"],
body.login input[type="checkbox"],
body.login input[type="password"] {
  box-shadow: none;
  background-color: white;
  transition: border-color 0.3s, background-color 0.3s;
  color: black; }

body.login input[type="text"]:focus,
body.login input[type="checkbox"]:focus,
body.login input[type="password"]:focus {
  outline: none;
  border-color: #dbd9d9;
  box-shadow: none; }

body.login input[type="checkbox"] {
  border: 1px solid #dbd9d9;
  border-color: #dbd9d9;
  margin-top: 0 !important; }

body.login input[type="text"],
body.login input[type="password"] {
  display: block;
  width: 100%;
  height: 39px;
  padding: 9px 10px 10px;
  border-radius: 3px;
  border: 1px solid #dbd9d9;
  background: #f5f6f6;
  font-size: 14px;
  color: #858585;
  font-weight: bold;
  transition: border .4s, box-shadow .4s; }

body.login #wp-submit,
body.login input.button-primary {
  padding: 13px 30px;
  background-color: #d3ccbc;
  border: 0;
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  letter-spacing: .02em;
  transition: background-color .4s ease;
  text-shadow: none;
  height: auto; }

body.login #login_error,
body.login .message {
  color: #323443;
  background-color: white;
  line-height: 1.6em; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; }
